// src/components/UserProfile.js
import React, { useState, useEffect } from "react";
import {  Container,  Row,  Col,  Card,  Form,  Button,  Spinner} from "react-bootstrap";
import moment from "moment-timezone"; // Import moment-timezone

function UserProfile() {
  const [formData, setFormData] = useState({
    name: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    country: "",
    stateOrProvince: "",
    city: "",
    postalCode: "",
    timezone: "", // Selected timezone
    utcOffset: 0, // UTC offset in hours
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem("userToken");
      try {
        const response = await fetch(
          "http://localhost:3000/user/getuserprofile",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user profile.");
        }

        const data = await response.json();

        // Ensure data is not null before trying to access timezone
        if (data && data.timezone) {
          setFormData((prevData) => ({
            ...data,
            timezone: data.timezone || "", // Set timezone from fetched data, default to empty string
          }));
        }
      } catch (err) {
        console.error(err);
        setError("Failed to load user profile.");
      }
    };

    const fetchTimezones = () => {
      const timezoneList = moment.tz.names(); // Get all timezone names
      setTimezones(timezoneList);
    };

    const fetchDefaultTimezone = async () => {
      try {
        const response = await fetch("https://worldtimeapi.org/api/ip");
        const data = await response.json();
        const defaultTimezone = data.timezone;
        console.log("Fetched default timezone:", defaultTimezone); // Debug log
        setFormData((prevData) => ({
          ...prevData,
          timezone: prevData.timezone , // Set the default timezone
        }));
      } catch (err) {
        console.error(err);
        setError("Failed to fetch default timezone.");
      }
    };

    fetchUserProfile();
    fetchTimezones();
    fetchDefaultTimezone();
  }, []);

  // Calculate UTC offset based on selected timezone
  const calculateUtcOffset = (timezone) => {
    const date = new Date();
    const utcDate = new Date(
      date.toLocaleString("en-US", { timeZone: timezone })
    );
    const offset = Math.floor(
      (utcDate.getTime() - date.getTime()) / (1000 * 60 * 60)
    ); // Offset in hours, rounded down to nearest integer
    return offset;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "timezone") {
      const utcOffset = calculateUtcOffset(value);
      setFormData((prevData) => ({ ...prevData, [name]: value, utcOffset }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const token = localStorage.getItem("userToken");

    try {
      const response = await fetch("http://localhost:3000/user/updateprofile", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Error updating profile");
      }

      setSubmitted(true);
      setError("");
    } catch (err) {
      console.error(err);
      setError("Failed to update profile, please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="my-5">
    <h2 className="text-center mb-4">Atualizar Perfil</h2>
    <Row className="justify-content-center">
      <Col md={12}>
        <Card>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formName">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Digite seu nome"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formAddressLine1">
                    <Form.Label>Endereço Linha 1</Form.Label>
                    <Form.Control
                      type="text"
                      name="addressLine1"
                      placeholder="Digite seu endereço"
                      value={formData.addressLine1}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formCountry">
                    <Form.Label>País</Form.Label>
                    <Form.Control
                      type="text"
                      name="country"
                      placeholder="Digite seu país"
                      value={formData.country}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formCity">
                    <Form.Label>Cidade</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      placeholder="Digite sua cidade"
                      value={formData.city}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formPostalCode">
                    <Form.Label>Código Postal</Form.Label>
                    <Form.Control
                      type="text"
                      name="postalCode"
                      placeholder="Digite seu código postal"
                      value={formData.postalCode}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formAddressLine2">
                    <Form.Label>Endereço Linha 2 (opcional)</Form.Label>
                    <Form.Control
                      type="text"
                      name="addressLine2"
                      placeholder="Digite sua linha de endereço 2"
                      value={formData.addressLine2}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formAddressLine3">
                    <Form.Label>Endereço Linha 3 (opcional)</Form.Label>
                    <Form.Control
                      type="text"
                      name="addressLine3"
                      placeholder="Digite sua linha de endereço 3"
                      value={formData.addressLine3}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formStateOrProvince">
                    <Form.Label>Estado ou Província (opcional)</Form.Label>
                    <Form.Control
                      type="text"
                      name="stateOrProvince"
                      placeholder="Digite seu estado ou província"
                      value={formData.stateOrProvince}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formTimezone">
                    <Form.Label>Fuso Horário</Form.Label>
                    <Form.Control
                      as="select"
                      name="timezone"
                      value={formData.timezone}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Selecione um fuso horário</option>
                      {timezones.map((tz) => (
                        <option key={tz} value={tz}>
                          {tz}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Button
                variant="primary"
                type="submit"
                className="w-100"
                disabled={loading}
              >
                {loading ? (
                  <Spinner as="span" animation="border" size="sm" />
                ) : (
                  "Atualizar Perfil"
                )}
              </Button>
            </Form>
            {submitted && (
              <div className="text-success mt-3">
                Perfil atualizado com sucesso!
              </div>
            )}
            {error && <div className="text-danger mt-3">{error}</div>}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
  );
}

export default UserProfile;
