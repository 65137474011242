// src/components/Home.js
import React from 'react';
import { Carousel, Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Home() {

  const { t } = useTranslation();
  return (
    <>
    {/* Welcome Text */}
    <Container className="my-5 text-center">
            <h1 className='textVet'>{t('welcome_message')}</h1>
            <p><h3><b>{t('slogan')}</b></h3></p>
      </Container>

      {/* Carousel */}
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./assets/Imag12.webp"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>{t('Carousel1Slogan')}</h3>
            <p>{t('Carousel1Text')} </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./assets/Imag22.webp"
            alt="Second slide"
          />
          <Carousel.Caption>
          <h3>{t('Carousel2Slogan')}</h3>
          <p>{t('Carousel2Text')} </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <Container className="my-5">
        <h5 className="text-center mb-4">Descubra como podemos ajudar a otimizar seus processos anestésicos e melhorar os cuidados com seus pacientes.</h5>

        <h2 className="text-center mb-4">Como Funciona a Teleanestesia?</h2>
        <Container className="my-5">
          <Row>
            <Col md={4}>
              <Card className="cardheight">
                <Card.Body>
                  <Card.Title>Agendamento</Card.Title>
                  <Card.Text>Entre em contato conosco para agendar uma sessão de teleanestesia para um procedimento específico. </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="cardheight">
                <Card.Body>
                  <Card.Title>Preparação</Card.Title>
                  <Card.Text>Antes do procedimento, enviamos os detalhes do plano anestésico personalizado, baseado no animal e no procedimento cirúrgico.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="cardheight"> 
                <Card.Body>
                  <Card.Title>Acompanhamento em Tempo Real</Card.Title>
                  <Card.Text>Durante o procedimento, a nossa equipa monitorizará o paciente remotamente, orientando sua equipa em tempo real para garantir que o processo anestésico ocorra de forma segura e tranquila.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>

       {/* Black Transparent Background Section */}
       <section className="black-green-section">
        <Container className="text-center text-white py-5">
          <h2>Sobre Nós</h2>
          <p>A DS VetAnesthesia: Excelência em Teleanestesia e Consultoria Veterinária</p>
          <p>Com vasta experiência em anestesia veterinária, a DS VetAnesthesia nasceu com o propósito de que mais animais possam ser melhor anestesiados.</p>
          <p>A nossa equipa é composta por médicos veterinários anestesistas dedicados e apaixonados pelo bem-estar e segurança animal.  </p>
          <p>Através da teleanestesia, proporcionamos um plano anestésico e acompanhamento remoto em tempo real, garantindo que cada animal receba o melhor cuidado possível. </p>
          <p>Oferecemos serviços de consultoria na área de anestesia e analgesia bem como formação.</p>
        </Container>
      </section>
    </>
  );
}

export default Home;
