import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import { Navbar, Nav, Container, Image } from "react-bootstrap";
import Home from "./components/Home";
import Services from "./components/Services";
import Contact from "./components/Contact";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import Login from "./components/Login"; // Import the Login component
import Footer from "./components/Footer";
import Register from "./components/Register";
import CookieBar from "./components/CookieBar";
import Dashboard from "./backoffice/Dashboard";
import PrivateRoute from "./components/PrivateRoute"; // Import the PrivateRoute
//Users
import Users from "./components/users/userslist"; // Import the Users component
import UserProfile from "./userprofile/MyProfile.js"; // Import the Users component

//internal
import AvailabilityInternal from "./backoffice/Internal/Availability"; // Import the Users component

import { jwtDecode } from "jwt-decode";
import "./App.css"; // Import your CSS

function App() {
  const { t } = useTranslation();

  // Manage the authentication state and user role
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    role: null,
  });

  // Function to check if the token is valid and return user role
  const checkAuthentication = () => {
    const token = localStorage.getItem("userToken");
    if (!token) return { isAuthenticated: false, role: null };

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        localStorage.removeItem("userToken");
        return { isAuthenticated: false, role: null };
      }

      return { isAuthenticated: true, role: decodedToken.role }; // Assuming your token has a `role` field
    } catch (error) {
      console.error("Token is invalid:", error);
      return { isAuthenticated: false, role: null };
    }
  };

  // Use useEffect to update the authentication status when the app loads
  useEffect(() => {
    setAuthState(checkAuthentication());
  }, []); // Empty array means this will only run on mount

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem("userToken"); // Remove the token
    setAuthState({ isAuthenticated: false, role: null }); // Update authentication state
    window.location.href = "/"; // Redirect to home page
  };

  return (
    <Router>
      <div className="app-container">
        <Navbar bg="white" expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <img
                src="/assets/newlogo.jpeg"
                width="250"
                className="d-inline-block align-top"
                alt="DS"
              />{" "}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto d-flex align-items-center">
                {!authState.isAuthenticated ? (
                  <>
                    <Nav.Link href="/">{t("Vetanesthesia")}</Nav.Link>
                    <Nav.Link href="/services">{t("Services")}</Nav.Link>
                    <Nav.Link href="/contact">{t("Contact")}</Nav.Link>
                  </>
                ) : null}
                {authState.isAuthenticated ? (
                  <>
                    <Nav.Link href="/dashboard">{t("Dashboard")}</Nav.Link>

                    {/* Conditional links based on user role */}
                    {authState.role === "Admin" && (
                      <Nav.Link href="/UserList">{t("Users")}</Nav.Link>
                    )}
                    {authState.role === "Client" && (
                      <Nav.Link href="/client">{t("Client Area")}</Nav.Link>
                    )}
                    {authState.role === "Anesthetist" && (
                      <Nav.Link href="/AvailabilityInternal">
                        {t("Anesthetist Area")}
                      </Nav.Link>
                    )}

                    <Nav.Link onClick={handleLogout}>{t("Logout")}</Nav.Link>
                    

                    {/* Profile Thumbnail */}
                    <Nav.Link href="/Myprofile">
                      <Image
                        src={
                          authState.profileImage ||
                          "/assets/default-profile.webp"
                        } // Default image if none is provided
                        roundedCircle
                        width={40}
                        height={40}
                        alt="Profile"
                        className="me-2" // Add some margin to the right
                      />
                    </Nav.Link>
                  </>
                ) : (
                  <Nav.Link href="/login">{t("Login")}</Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="content-wrap">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} /> 
            {/* <Route path="/login" element={<Login onLogin={setAuthState} />} /> */}
            <Route path="/register" element={<Register />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/UserList"
              element={
                <PrivateRoute requiredRoles={["Admin", "Anesthetist"]}>
                  <Users />
                </PrivateRoute>
              }
            />
            <Route
              path="/AvailabilityInternal"
              element={
                <PrivateRoute requiredRoles={["Anesthetist"]}>
                  <AvailabilityInternal />
                </PrivateRoute>
              }
            />
            <Route
              path="/MyProfile"
              element={
                <PrivateRoute>
                  <UserProfile/>
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
        <Footer />
        <CookieBar />
      </div>
    </Router>
  );
}

export default App;
