// src/components/Contact.js
import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    honeypot: '', // Hidden field for honeypot
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // New loading state

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if the honeypot field is empty
    if (formData.honeypot) {
      alert("Por favor, prove que não é um robot.");
      return;
    }

    setLoading(true); // Set loading to true when form is submitted

    try {
      const response = await fetch('https://dsvetanesthesia.com:3000/email/sendmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Error sending email');
      }

      // Reset the form after successful submission
      setFormData({ name: '', email: '', message: '', honeypot: '' });
      setSubmitted(true);
      setError(''); // Clear any previous error message
    } catch (err) {
      console.error(err);
      setError('Failed to send message, please try again.');
    } finally {
      setLoading(false); // Set loading to false after the request completes
    }
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Entre em contacto connosco!</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Digite o seu nome"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Digite o seu email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formMessage">
                  <Form.Label>Escreva a sua messagem:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="message"
                    placeholder="Digite sua mensagem"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                {/* Honeypot field */}
                <Form.Group style={{ display: 'none' }} controlId="formHoneypot">
                  <Form.Control
                    type="text"
                    name="honeypot"
                    placeholder="Leave empty"
                    value={formData.honeypot}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100" disabled={loading}>
                  {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Enviar mensagem'}
                </Button>
              </Form>
              {submitted && <div className="text-success mt-3">Mensagem enviada com sucesso!</div>}
              {error && <div className="text-danger mt-3">{error}</div>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
