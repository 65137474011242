// src/components/CookieBar.js
import React, { useState, useEffect } from 'react';


function CookieBar() {
  const [showCookieBar, setShowCookieBar] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent1');
    if (!cookieConsent) {
      setShowCookieBar(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookieConsent1', 'true');
    setShowCookieBar(false);
  };

  if (!showCookieBar) return null;

  return (
    <div className="cookie-bar">
      <p>
      Utilizamos cookies para garantir que lhe proporcionamos a melhor experiência no nosso site. Ao continuar a utilizar este site, você concorda com o uso de cookies.
      </p>
      <button className="accept-btn" onClick={handleAcceptCookies}>
        Aceitar
      </button>
    </div>
  );
}

export default CookieBar;
