import React, { useState, useEffect } from 'react';
import { Container, Button, Modal, Card, CardBody } from "react-bootstrap";
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import axios from 'axios'; // Axios for API requests

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const [businessHours, setBusinessHours] = useState([]);

 /*  // Define business hours for each day
  const businessHours = [
    { daysOfWeek: [0], startTime: '12:00', endTime: '16:00' }, // Sunday
    { daysOfWeek: [1], startTime: '09:00', endTime: '17:00' }, // Monday
    { daysOfWeek: [2], startTime: '09:00', endTime: '17:00' }, // Tuesday
    { daysOfWeek: [3], startTime: '09:00', endTime: '17:00' }, // Wednesday
    { daysOfWeek: [4], startTime: '09:00', endTime: '17:00' }, // Thursday
    { daysOfWeek: [5], startTime: '09:00', endTime: '17:00' }, // Friday
    { daysOfWeek: [6], startTime: '10:00', endTime: '14:00' }, // Saturday
  ]; */

  useEffect(() => {
    const fetchBusinessHours = async () => {
      try {
        // Example API call
        const response = await axios.get('https://api.example.com/business-hours');
        setBusinessHours(response.data); // Assuming response.data is an array of business hours
      } catch (error) {
        console.error('Error fetching business hours:', error);
      }
    };

    fetchBusinessHours();
  }, []); // Empty dependency array to run only once when component mounts

  const handleDateSelect = (selectInfo) => {
    const { start, end } = selectInfo;

    // Get the day of the week for the selected date
    const dayOfWeek = start.getDay();

    // Get the allowed hours for the selected day
    const allowedHours = businessHours.find(bh => bh.daysOfWeek.includes(dayOfWeek));

    const startHour = start.getHours();
    const endHour = end.getHours();

    // Validate selected time against allowed hours
    if (
      allowedHours &&
      (startHour >= parseInt(allowedHours.startTime.split(':')[0], 10) && 
      startHour < parseInt(allowedHours.endTime.split(':')[0], 10)) &&
      (endHour > parseInt(allowedHours.startTime.split(':')[0], 10) && 
      endHour <= parseInt(allowedHours.endTime.split(':')[0], 10))
    ) {
      const newEvent = {
        id: Date.now(),
        title: 'New Event',
        start: start,
        end: end,
        allDay: false,
      };
      setEvents([...events, newEvent]);
    } else {
      alert(`Please select a time between ${allowedHours.startTime} and ${allowedHours.endTime} on this day.`);
    }
  };

  return (
    <>
    <Container className="my-5">
        <h2 className="text-center mb-4">Os meus agendamentos</h2>
        <h5 className="text-center mb-4">Só existem Anestesistas disponíveis nos slots permitidos</h5>
        {/* Display Calendar */}
        <Card>
          <Card.Body>
          <FullCalendar
                plugins={[interactionPlugin, timeGridPlugin]} // Include both plugins
                initialView="timeGridWeek"
                events={events}
                selectable={true}
                editable={true}
                height="auto"
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                }}
                select={handleDateSelect}
                businessHours={businessHours} // Display business hours on the calendar
                slotDuration="00:30:00" // 30-minute slots for better visibility
                slotLabelInterval="01:00" // Show hour labels
                nowIndicator={true} // Show a line indicating the current time
                slotMinTime={"09:00:00"} // Set minimum time to 6 AM
                slotMaxTime={"18:00:00"} // Set maximum time to 6 PM
                allDaySlot={false} // Hide "all-day" slot
              />
          </Card.Body>
        </Card>
        </Container>
    </>
  );
};

export default Calendar;



/* import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import axios from 'axios'; // Axios for API requests

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const [businessHours, setBusinessHours] = useState([]);

  // Fetch business hours from API
  useEffect(() => {
    const fetchBusinessHours = async () => {
      try {
        // Example API call
        const response = await axios.get('https://api.example.com/business-hours');
        setBusinessHours(response.data); // Assuming response.data is an array of business hours
      } catch (error) {
        console.error('Error fetching business hours:', error);
      }
    };

    fetchBusinessHours();
  }, []); // Empty dependency array to run only once when component mounts

  const handleDateSelect = (selectInfo) => {
    const { start, end } = selectInfo;

    // Get the day of the week for the selected date
    const dayOfWeek = start.getDay();

    // Get the allowed hours for the selected day
    const allowedHours = businessHours.find(bh => bh.daysOfWeek.includes(dayOfWeek));

    const startHour = start.getHours();
    const endHour = end.getHours();

    // Validate selected time against allowed hours
    if (
      allowedHours &&
      (startHour >= parseInt(allowedHours.startTime.split(':')[0], 10) && 
      startHour < parseInt(allowedHours.endTime.split(':')[0], 10)) &&
      (endHour > parseInt(allowedHours.startTime.split(':')[0], 10) && 
      endHour <= parseInt(allowedHours.endTime.split(':')[0], 10))
    ) {
      const newEvent = {
        id: Date.now(),
        title: 'New Event',
        start: start,
        end: end,
        allDay: false,
      };
      setEvents([...events, newEvent]);
    } else {
      alert(`Please select a time between ${allowedHours.startTime} and ${allowedHours.endTime} on this day.`);
    }
  };

  return (
    <FullCalendar
      plugins={[interactionPlugin, timeGridPlugin]} // Include both plugins
      initialView="timeGridWeek"
      events={events}
      selectable={true}
      editable={true}
      select={handleDateSelect}
      businessHours={businessHours} // Display dynamic business hours from API
      slotDuration="00:30:00" // 30-minute slots for better visibility
      slotLabelInterval="01:00" // Show hour labels
      nowIndicator={true} // Show current time indicator
      minTime="08:00:00" // Start calendar day from 8 AM
      maxTime="18:00:00" // End calendar day at 6 PM
      allDaySlot={false} // Hide all-day slot
    />
  );
};

export default Calendar; */
