// src/components/Services.js
import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';

function Services() {
  const [showModal, setShowModal] = useState({
    consultaPreAnestesica: false,
    dorCronica: false,
    planoAnestesico: false,
    teleanestesia: false,
    consultoria: false,
    formacao: false
  });

  const handleShow = (modalName) => {
    setShowModal((prevState) => ({
      ...prevState,
      [modalName]: true
    }));
  };

  const handleClose = (modalName) => {
    setShowModal((prevState) => ({
      ...prevState,
      [modalName]: false
    }));
  };

  return (
    <div>
      <Container className="my-5">
        <h2 className="text-center mb-4">Os Nossos Serviços</h2>
        <Row>
          <Col md={4}>
            <Card>
              <Card.Img variant="top" src="./assets/pranestesica.jpg" />
              <Card.Body>
                <Card.Title>Consulta pré-anestesica</Card.Title>
                <Card.Text>Uma consulta pré-anestésica é uma avaliação clínica realizada por um médico veterinário  antes de um procedimento que requeira sedação ou anestesia geral. O objetivo dessa consulta é identificar e minimizar riscos associados à anestesia, garantindo a segurança do paciente durante o procedimento.</Card.Text>
                <Button variant="primary" onClick={() => handleShow('consultaPreAnestesica')}>
                  Mais Detalhes
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <Card.Img variant="top" src="./assets/dorcronica.jpg" />
              <Card.Body>
                <Card.Title>Consulta de dor crónica</Card.Title>
                <Card.Text>Com foco no diagnóstico e tratamento da dor crónica em animais de estimação, a consulta de medicina de dor pretende destacar-se pelo cuidado personalizado, especialmente concebido para animais com condições como osteoartrite, distúrbios neurológicos ou oncológicos.</Card.Text>
                <Button variant="primary" onClick={() => handleShow('dorCronica')}>
                  Mais Detalhes
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <Card.Img variant="top" src="./assets/planoanestesico.jpg" />
              <Card.Body>
                <Card.Title>Plano anestésico</Card.Title>
                <Card.Text>Desenvolvimento de um plano anestésico adequado às necessidades do paciente, considerando fatores como idade, peso, condições clínicas e tipo de cirurgia.</Card.Text>
                {/* <Button variant="primary" onClick={() => handleShow('planoAnestesico')}>
                  Mais Detalhes
                </Button> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container className="my-5">
        <Row>
          <Col md={4}>
            <Card>
              <Card.Img variant="top" src="./assets/TeleAnestesia.jpeg" />
              <Card.Body>
                <Card.Title>Teleanestesia (plano anestésico + acompanhamento)</Card.Title>
                <Card.Text>A teleanestesia é um serviço inovador que permite realizar procedimentos anestésicos à distância, utilizando tecnologias de comunicação seguras e avançadas. Através deste serviço, proporcionamos uma alternativa, para que mais animais possam ter um anestesista</Card.Text>
                <Button variant="primary" onClick={() => handleShow('teleanestesia')}>
                  Mais Detalhes
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <Card.Img variant="top" src="./assets/Vigilancia.jpg" />
              <Card.Body>
                <Card.Title>Consultoria</Card.Title>
                <Card.Text>Oferecemos um serviço especializado de consultoria em anestesiologia veterinária para hospitais veterinários, com o objetivo de otimizar a segurança e a eficiência nos procedimentos anestésicos. Através de uma auditoria abrangente, analisamos todas as etapas do processo anestésico, desde a recepção e comunicação com o tutor, preparação, monitorização e recuperação dos pacientes.</Card.Text>
                <Button variant="primary" onClick={() => handleShow('consultoria')}>
                  Mais Detalhes
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <Card.Img variant="top" src="./assets/formacao.jpg" />
              <Card.Body>
                <Card.Title>Formação</Card.Title>
                <Card.Text>Oferecemos serviços de formação especializada na área de anestesia e analgesia. Este tipo de formação é personalizada e realizada diretamente na clínica ou hospital, abrangendo diferentes ramos da anestesiologia veterinária. Proporcionamos sessões de formação ajustadas às necessidades dos diferentes colegas, ajudando a melhorar as suas competências e conhecimentos nesta área essencial, garantindo assim o melhor cuidado para os seus pacientes</Card.Text>
                {/* <Button variant="primary" onClick={() => handleShow('formacao')}>
                  Mais Detalhes
                </Button> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modals for each card */}
      <Modal size="xl" show={showModal.consultaPreAnestesica} onHide={() => handleClose('consultaPreAnestesica')} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Consulta pré-anestesica</Modal.Title>
        </Modal.Header>
        <Modal.Body><p>Uma consulta pré-anestésica é uma avaliação clínica realizada por um médico veterinário  antes de um procedimento que requeira sedação ou anestesia geral. O objetivo dessa consulta é identificar e minimizar riscos associados à anestesia, garantindo a segurança do paciente durante o procedimento.</p>
                <p><b>Consulta Pré-Anestésica:</b></p>
                <ul>
                  <li>Avaliação do Estado de Saúde: Avaliação do histórico médico do paciente, incluindo doenças pré-existentes, medicamentos atuais, alergias e histórico de cirurgias anteriores.</li>
                  <li>Identificação de Riscos: Análise de possíveis complicações anestésicas.</li>
                  <li>Plano Anestésico Personalizado: Desenvolvimento de um plano anestésico adequado às necessidades do paciente, considerando fatores como idade, peso, condições clínicas e tipo de cirurgia.</li>
                  <li>Orientações e Esclarecimentos: Esclarecimento de dúvidas dos tutores, relativamente aos riscos e possíveis complicações associadas à sedação ou anestesia geral do seu paciente.</li>
                </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose('consultaPreAnestesica')}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl" show={showModal.dorCronica} onHide={() => handleClose('dorCronica')} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Consulta de dor crónica</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Tendo como principal objectivo controlar a dor e melhorar a qualidade de vida dos animais. Os nossos planos de tratamento personalizados incluem medicação, infiltrações e bloqueios regionais</p>
          <p><b>O que é a Dor Crónica?</b></p>
            A dor crónica é uma dor de longa duração que persiste além do período habitual de recuperação após uma lesão ou cirurgia. Pode também estar associada a condições de saúde crónicas, como osteoartrite, otite crónica ou distúrbios neurológicos. A dor crónica pode ser intermitente ou contínua e durar semanas ou meses, mesmo após a cicatrização inicial da lesão. O mais importante é que a dor crónica afecta negativamente a qualidade de vida dos animais de estimação, impedindo-os de viver felizes e de realizar as suas funções corporais normais.
            Como os animais não podem comunicar como se sentem, é importante reconhecer sinais comportamentais associados à dor crónica. Estes sinais incluem:
          	<ul>
              <li>Diminuição da atividade ou relutância em brincar</li>
              <li>Dificuldade em subir ou descer escadas</li>
              <li>Lamber ou morder uma área específica</li>
              <li>Vocalizar com mais frequência</li>
              <li>Alterações no apetite ou nos padrões de sono</li>
              <li>Irritabilidade ou agressividade</li>
              <li>Diminuição de grooming</li>
            </ul>
            <p><b>Condições que podem causar dor crónica em cães e gatos incluem:</b></p>
	          <ul>
              <li>Musculares e esqueléticas (por exemplo, displasia da anca, doença articular degenerativa, lesões na cauda)</li>
              <li>Dentárias (por exemplo, gengivite, estomatite, abcessos orais)</li>
              <li>Tumores (por exemplo, osteossarcoma, mieloma múltiplo, sarcomas de tecidos moles)</li>
              <li>Neurológicas (por exemplo, doenças degenerativas, mielopatia degenerativa)</li>
            </ul>

            <p><b>Como Funciona a Clínica de Medicina da Dor?</b></p>
            Após ser encaminhado pelo médico veterinário, realizaremos uma avaliação inicial detalhada. Esta incluirá a recolha de um historial médico completo, com registo dos medicamentos passados e atuais e seus efeitos, bem como quaisquer alterações no comportamento do seu animal, apetite, mobilidade, postura e rotina de sono.
            Será realizado um exame físico completo, escalas de avaliação da dor e questionários validados para a dor crónica.
            Com base nos resultados e informações obtidas, discutiremos e desenvolveremos um plano personalizado de alívio da dor, adaptado às necessidades do animal. Este plano pode envolver testes de diagnóstico adicionais, como análises de sangue, exames de imagem (por exemplo, tomografias ou ressonâncias magnéticas, radiografias). Normalmente, o plano será baseado numa abordagem multimodal, podendo incluir a administração de medicamentos para alívio da dor, bloqueios regionais guiados por ecografia, medicamentos específicos administrados através de injeções epidurais ou locais.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose('dorCronica')}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal.planoAnestesico} onHide={() => handleClose('planoAnestesico')}>
        <Modal.Header closeButton>
          <Modal.Title>Plano anestésico</Modal.Title>
        </Modal.Header>
        <Modal.Body>Descrição do plano anestésico...</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose('planoAnestesico')}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl" show={showModal.teleanestesia} onHide={() => handleClose('teleanestesia')} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Teleanestesia</Modal.Title>
        </Modal.Header>
        <Modal.Body>A teleanestesia é um serviço inovador que permite realizar procedimentos anestésicos à distância, utilizando tecnologias de comunicação seguras e avançadas. Através deste serviço, proporcionamos uma alternativa, para que mais animais possam ter um anestesista
        <p></p>
              <p><b>Como funciona?</b></p>
              <ul>
              <li><b>Agendamento:</b> Para marcar uma teleanestesia, basta entrar em contacto connosco através do nosso site ou e-mail. Encontrando a nossa disponibilidade agendamos a sessão num horário que seja conveniente para si.</li>
              <li><b>Envio de Equipamento:</b> Antes do procedimento, enviamos uma câmara de alta definição e todo o equipamento necessário para garantir uma comunicação visual de qualidade.</li>
              <li><b>Plano Anestésico:</b>Até 12 horas antes do procedimento, fornecemos um plano anestésico personalizado, ajustado às condições do paciente ao tipo de intervenção a realizar.</li>
              <li><b>Monitorização em Tempo Real:</b> No dia e hora marcada, o nosso anestesiologista estará disponível para acompanhar todo o processo à distância, monitorizando em tempo real. A câmara permitirá uma visualização detalhada e uma interação contínua entre o colega local e o anestesiologista remoto. Aumentando a segurança e conforto do paciente. </li>
              </ul>
              <p>Entre em contacto connosco para saber mais e agende a sua sessão de teleanestesia de forma simples e rápida!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose('teleanestesia')}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl"show={showModal.consultoria} onHide={() => handleClose('consultoria')} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Consultoria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
              Oferecemos um serviço especializado de consultoria em anestesiologia veterinária para hospitais veterinários, com o objetivo de otimizar a segurança e a eficiência nos procedimentos anestésicos. Através de uma auditoria abrangente, analisamos todas as etapas do processo anestésico, desde a recepção e comunicação com o tutor, preparação, monitorização e recuperação dos pacientes. O objetivo é identificar os pontos e áreas a melhorar.
              <p></p>Neste serviço a avaliamos os delineamentos anestésicos, o uso de equipamentos, rotinas e práticas. Além disto é avaliada de uma forma detalhada as políticas de maneio de dor desde a consulta à hospitalização. Após a auditoria, apresentamos relatórios completos com propostas de melhoria e soluções personalizadas, focadas em reduzir complicações, aumentar a segurança do paciente e promover a autonomia da equipa.
              <p></p>Com nossa expertise, ajudamos sua equipa a adotar as melhores práticas baseadas em evidências, elevando o padrão dos cuidados oferecido e proporcionando um ambiente mais seguro e eficiente para os seus pacientes.
              <p></p>Entre em contato para saber como podemos apoiar o desenvolvimento do seu hospital e aprimorar a qualidade dos seus serviços.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose('consultoria')}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal.formacao} onHide={() => handleClose('formacao')}>
        <Modal.Header closeButton>
          <Modal.Title>Formação</Modal.Title>
        </Modal.Header>
        <Modal.Body>Oferecemos serviços de formação especializada na área de anestesia e analgesia. Este tipo de formação é personalizada e realizada diretamente na clínica ou hospital, abrangendo diferentes ramos da anestesiologia veterinária. Proporcionamos sessões de formação ajustadas às necessidades dos diferentes colegas, ajudando a melhorar as suas competências e conhecimentos nesta área essencial, garantindo assim o melhor cuidado para os seus pacientes</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose('formacao')}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Services;