import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');  // Add username field
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await fetch('http://localhost:3000/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,  // Include username in the registration request
          email,
          password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log('User registered successfully', data);
        // Optionally, you can redirect the user to the login page after registration
        navigate('/login');
      } else {
        setError(data.message || 'An error occurred during registration');
      }
    } catch (error) {
      setError('An error occurred: ' + error.message);
    }
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
      <Col md={6}>
          <Image 
              src="./assets/camera.png" 
              fluid 
              alt="description of image"
            />
        </Col>
        <Col md={6}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Registar novo cliente</h2>
              {error && <p className="text-danger text-center">{error}</p>}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicUsername" className="mb-3">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Introduzir Nome"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formBasicEmail" className="mb-3">
                  <Form.Label>Endereço de Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Introduzir endereço de email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="mb-3">
                  <Form.Label>Palavra-chave</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Introduzir palavra-chave"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formBasicConfirmPassword" className="mb-3">
                  <Form.Label>Palavra-chave</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirmar palavra-chave"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Registar como novo cliente
                </Button>
              </Form>
              <div className="text-center mt-3">
                <Link to="/login">Já tens uma conta? Entrar</Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Register;
